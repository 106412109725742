import React, { Suspense, lazy, useEffect } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
/* loader component for Suspense */
import PageLoader from './components/Common/PageLoader';

/* eslint-disable-next-line */
import Core from './components/Core/Core';
/* eslint-disable-next-line */
import Bootstrap from './components/Bootstrap/Bootstrap';
/* eslint-disable-next-line */
import Common from './components/Common/Common';
/* eslint-disable-next-line */
import Colors from './components/Colors/Colors';
/* eslint-disable-next-line */
import FloatButton from './components/FloatButton/FloatButton';
/* eslint-disable-next-line */
import Utils from './components/Utils/Utils';

import User from './views/User/User';
import { getCookieByName } from './helpers/getCookieByName';
import { checkLocalToken } from './services/UserSSOService';
import LoginRequireScreen from './views/NoLoginCP/LoginRequireScreen';
import { UnauthorizedScreen } from './views/NoLoginCP/UnauthorizedScreen';
import "react-block-ui/style.css";

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;


const Estudios = lazy(() => import('./views/Estudios/Estudios'));
const DownloadFiles = lazy(() => import('./views/Download/DownloadFiles'));
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));
const DashboardCalidad = lazy(() => import('./views/Dashboard/DashboardCalidad'));
const PlazaPublica = lazy(() => import('./views/Dashboard/PlazaPublica'));
const Panelistas = lazy(() => import('./views/Panelistas/Panelistas'));
const Participacion = lazy(() => import('./views/Panelistas/Participacion'));
const Ganadores = lazy(() => import('./views/Ganadores/Ganadores'));
const PageNotFound = lazy(() => import('./views/Errors/PageNotFound'));
const Unsubscribe = lazy(() => import('./views/Panelistas/Unsubscribe'));
const UsersMainScreen = lazy(() => import('./views/UserSSO/UsersMainScreen'));
const MarcoMuestral = lazy(() => import('./views/MarcoMuestral/MarcoMuestral'));
const MarcoMuestralList = lazy(() => import('./views/MarcoMuestral/MarcoMuestralList'));
const MuestrasView = lazy(() => import('./views/MarcoMuestral/MuestreoTab/index'));
const CrearMuestra = lazy(() => import('./views/MarcoMuestral/MuestreoTab/Muestreo'));


const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    const animationName = 'rag-fadeIn';

    const localToken = getCookieByName() || '';
    const logged = localStorage.getItem('log-ged') || '';
    const rolUser = localStorage.getItem('rol') || '';

    useEffect(() => {
        if(location.pathname !== "/unauthorized"){
            checkLocalToken()
        }
    }, [ location.pathname ])


    if(logged){
        return (
            <Switch>
                <Route exact path="/unauthorized" component={UnauthorizedScreen} />
                <Redirect to="/unauthorized" />
            </Switch>
        )
    }

    if(!localToken || !rolUser){
        return (
            <Suspense fallback={<PageLoader/>}>
                <Switch>
                    <Route exact path="/" component={LoginRequireScreen} />
                    <Route exact path="/unauthorized" component={UnauthorizedScreen} />
                    <Redirect to="/"/>
                </Switch>
            </Suspense>
        )
    }

    if(rolUser === 'rol-admin'){
        return (
            <Core>
                <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>
                                {/* descargas */}
                                <Route path="/downloadfiles" component={waitFor(DownloadFiles)}/>
                                {/* dashboard */}
                                <Route path="/dashboard" component={waitFor(Dashboard)}/>
                                <Route path="/dashboardcalidad" component={waitFor(DashboardCalidad)}/>
                                <Route path="/plaza-publica" component={waitFor(PlazaPublica)}/>
                                {/* estudios */}
                                <Route path="/estudios" component={waitFor(Estudios)}/>
                                <Route path="/lista-marcomuestral" component={waitFor(MarcoMuestralList)}/>   
                                <Route path="/marcomuestral" component={waitFor(MarcoMuestral)}/>
                                <Route path="/muestras" component={waitFor(MuestrasView)}/>
                                <Route path="/crear-muestra" component={waitFor(CrearMuestra)}/>
                                {/* ganadores */}
                                <Route path="/ganadores" component={waitFor(Ganadores)} />
                                {/* panelistas */}
                                <Route path="/panelistas" component={waitFor(Panelistas)}/>
                                <Route path="/unsubscribe" component={waitFor(Unsubscribe)}/>
                                {/* usuarios */}
                                <Route path="/usuarios" component={waitFor(UsersMainScreen)}/>
                                {/* PageNotFound */}
                                <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>
                                <Redirect to="/downloadfiles"/>
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }
    if(rolUser === 'rol-gestion'){
        return (
            <Core>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                    {/* descargas */}
                                    <Route path="/downloadfiles" component={waitFor(DownloadFiles)}/>
                                    {/* dashboard */}
                                    <Route path="/dashboard" component={waitFor(Dashboard)}/>
                                    <Route path="/dashboardcalidad" component={waitFor(DashboardCalidad)}/>
                                    <Route path="/plaza-publica" component={waitFor(PlazaPublica)}/>
                                    {/* estudios */}
                                    <Route path="/estudios" component={waitFor(Estudios)}/>
                                    {/* panelistas */}
                                    <Route path="/panelistas" component={waitFor(Panelistas)}/>
                                    {/* PageNotFound */}
                                    <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>
                                    <Redirect to="/PageNotFound"/>
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }
    if(rolUser === 'rol-gestion-admin'){
        return (
            <Core>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                    {/* descargas */}
                                    <Route path="/downloadfiles" component={waitFor(DownloadFiles)}/>
                                    {/* dashboard */}
                                    <Route path="/dashboard" component={waitFor(Dashboard)}/>
                                    <Route path="/dashboardcalidad" component={waitFor(DashboardCalidad)}/>
                                    <Route path="/plaza-publica" component={waitFor(PlazaPublica)}/>
                                    {/* estudios */}
                                    <Route path="/estudios" component={waitFor(Estudios)}/>
                                    {/* ganadores */}
                                    <Route path="/ganadores" component={waitFor(Ganadores)} />
                                    {/* panelistas */}
                                    <Route path="/panelistas" component={waitFor(Panelistas)}/>
                                    <Route path="/unsubscribe" component={waitFor(Unsubscribe)}/>
                                    {/* PageNotFound */}
                                    <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>
                                    <Redirect to="/PageNotFound"/>
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }
    if(localStorage.getItem("rol") === 'rol-finanzas'){
        return (
            <Core>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                    {/* estudios */}
                                    <Route path="/estudios" component={waitFor(Estudios)}/>
                                    <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>
                                    <Redirect to="/PageNotFound"/>
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }

}

export default withRouter(Routes);
