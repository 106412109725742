let rol = localStorage.getItem('rol');
let Menu = [];

// console.log('Menu Rol', rol);

switch(rol){
    case 'rol-admin':
        Menu = [
            {
                name: 'Descargas',
                path: '/downloadfiles',
                icon: 'img/icons/cloud-download.svg',
                label: { value: 1, classNme: 'bg-success' }
            },
            {
                name: 'Dashboard',
                path: '/dashboard',
                icon: 'img/icons/connection-bars.svg',
                label: { value: 1, classNme: 'bg-success' }
            },
            {
                name: 'Estudios',
                path: '/estudios',
                icon: 'img/icons/book.svg',
                label: { value: 1, classNme: 'bg-success' }
            },
            // {
            //     name: 'Marco Muestral',
            //     path: '/lista-marcomuestral',                
            //     icon: 'img/icons/analytics.svg',
            //     label: { value: 1, classNme: 'bg-success' }
            // },
            {
                name: 'Ganadores',
                icon: 'img/icons/clipboard.svg',
                path: '/ganadores'
            },
            {
                name: 'Panelistas',
                icon: 'img/icons/person-stalker.svg',
                submenu: [
                    {
                        name: 'Buscador',
                        path: '/panelistas'
                    },
                    {
                        name: 'Unsubscribe',
                        path: '/unsubscribe'
                    }
                ]
            },
            {
                name: 'Usuarios',
                path: '/usuarios',
                icon: 'img/icons/user-check.svg',
                label: { value: 1, classNme: 'bg-success' }
            }
        ];
    break;
    case 'rol-gestion':
        Menu = [
        {
            name: 'Descargas',
            path: '/downloadfiles',
            icon: 'img/icons/cloud-download.svg',
            label: { value: 1, classNme: 'bg-success' }
        },
        {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'img/icons/connection-bars.svg',
            label: { value: 1, classNme: 'bg-success' }
        },
        {
            name: 'Estudios',
            path: '/estudios',
            icon: 'img/icons/book.svg',
            label: { value: 1, classNme: 'bg-success' }
        },
        {
            name: 'Panelistas',
            icon: 'img/icons/person-stalker.svg',
            submenu: [
                {
                    name: 'Buscador',
                    path: '/panelistas'
                },
            ]
        }
        ];
    break;
    case 'rol-gestion-admin':
        Menu = [
        {
            name: 'Descargas',
            path: '/downloadfiles',
            icon: 'img/icons/cloud-download.svg',
            label: { value: 1, classNme: 'bg-success' }
        },
        {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'img/icons/connection-bars.svg',
            label: { value: 1, classNme: 'bg-success' }
        },
        {
            name: 'Estudios',
            path: '/estudios',
            icon: 'img/icons/book.svg',
            label: { value: 1, classNme: 'bg-success' }
        },
        {
            name: 'Ganadores',
            icon: 'img/icons/clipboard.svg',
            path: '/ganadores'
        },
        {
            name: 'Panelistas',
            icon: 'img/icons/person-stalker.svg',
            submenu: [
                {
                    name: 'Buscador',
                    path: '/panelistas'
                },
                {
                    name: 'Unsubscribe',
                    path: '/unsubscribe'
                }
            ]
        }
    ];
    break;
    case 'rol-finanzas':
        Menu = [
        {
            name: 'Estudios',
            path: '/estudios',
            icon: 'img/icons/book.svg',
            label: { value: 1, classNme: 'bg-success' }
        },
    ];
    break;
}

export default Menu;