import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";

import FormValidator from "../Forms/Validator.js";
import "../Forms/Material.scss";
import Swal from "sweetalert2";
import axios from "axios";
import { API_BASE_URL } from "../../configuration/index.js";
import { Fragment } from "react";

const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const Recover = () => {
  // estados
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // evento submit que solicita el envío del correo de recuperación.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const emailProcess = email.trim().toLowerCase();
    if (!emailProcess)
      return Swal.fire(
        "Error!",
        "Usted debe ingresar su email para recuperar su contraseña.",
        "error"
      );

    if (!regexEmail.test(emailProcess))
      return Swal.fire(
        "Error!",
        "Su correo no tiene un formato válido.",
        "error"
      );
    setIsLoading(true);

    await axios({
      url: API_BASE_URL + "/reset-password/" + email,
      method: "GET",
    })
      .then(({ data: responseData }) => {
        setIsLoading(false);
        return Swal.fire("Exito!", responseData.message, "success");
      })
      .catch(({ response: { data } }) => {
        setIsLoading(false);
        return Swal.fire("Error!", data.message, "error");
      });
  };

  return (
    <div className="container-full">
      <div className="container container-xs">
        <div className="text-center">
          <img
            className="mv-lg img-fluid thumb128"
            src="img/logo-cadem.svg"
            alt="Logo Cadem"
          />
        </div>
        <form
          className="cardbox b0 form-validate"
          name="formLogin"
          onSubmit={handleSubmit}
        >
          <div className="cardbox-offset pb0">
            <div className="cardbox-offset-item text-right invisible">
              <div className="btn btn-success btn-circle btn-lg">
                <em className="ion-checkmark-round" />
              </div>
            </div>
          </div>
          <div className="cardbox-heading">
            <div className="cardbox-title text-center">
              ¿Has olvidado tu contraseña?
            </div>
            <p className="text-center mt-1">
              Puede restablecer su contraseña aquí.
            </p>
          </div>
          <div className="cardbox-body">
            <div className="mda-form-group float-label mda-input-group">
              <div className="mda-form-control">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  disabled={isLoading}
                  onChange={({ target }) => setEmail(target.value)}
                />
                <div className="mda-form-control-line" />
              </div>
              <span className="mda-input-group-addon">
                <em className="ion-ios-email-outline icon-lg" />
              </span>
            </div>
          </div>

          <div className="cardbox-footer d-flex justify-content-between align-items-center">
            <Link to="/login" className="btn btn-purple text-decoration-none">
              Volver a iniciar sesión
            </Link>
            <button
              className="btn btn-purple"
              type="submit"
              disabled={isLoading}
            >
              {!isLoading ? (
                <span>Restablecer contraseña.</span>
              ) : (
                <Fragment>
                  <span
                    className="spinner-grow spinner-grow-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Cargando ...
                </Fragment>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Recover;
