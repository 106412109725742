import axios from 'axios';
import Swal from 'sweetalert2';
const config = require('../configuration')
// const API_BASE_URL = config.get('API_API_BASE_URL')

const API_BASE_URL = config.API_BASE_URL;
const qs = require('querystring');

export function LoginPost(username, password) {

    // const TokenUpload = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c3VhcmlvIjp7ImlkIjoxLCJuYW1lIjoibWd1ZXJyZXJvIiwiZW1haWwiOiJtYXR0aWFzLmd1ZXJyZXJvQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJhJDEwJDBDbEtlTzdtVnYwVmtoamxOVjRjb3U0RXgyREV1bGNySkFmdXFZZU1SUkMyNFlZUUZkR3dXIiwiYXBlbGxpZG9fcGF0ZXJubyI6Imd1ZXJyZXJvIiwiYXBlbGxpZG9fbWF0ZXJubyI6ImFodW1hZGEiLCJjcmVhdGVkX2F0IjoiMjAxOS0xMi0yMFQxNzo1Nzo0NS4wMDBaIiwidXBkYXRlZF9hdCI6IjIwMTktMTItMjBUMTc6NTc6NDUuMDAwWiJ9LCJpYXQiOjE1NzczNzkzMjAsImV4cCI6MTU3NzM5MzcyMH0.rxxH1QnwO3EqgQ7sXdI0D3S7huW8ybwO-l2G8qZAdQY';
    // const TokenDownload = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c3VhcmlvIjp7ImlkIjoxLCJuYW1lIjoibWd1ZXJyZXJvIiwiZW1haWwiOiJtYXR0aWFzLmd1ZXJyZXJvQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJhJDEwJDBDbEtlTzdtVnYwVmtoamxOVjRjb3U0RXgyREV1bGNySkFmdXFZZU1SUkMyNFlZUUZkR3dXIiwiYXBlbGxpZG9fcGF0ZXJubyI6Imd1ZXJyZXJvIiwiYXBlbGxpZG9fbWF0ZXJubyI6ImFodW1hZGEiLCJjcmVhdGVkX2F0IjoiMjAxOS0xMi0yMFQxNzo1Nzo0NS4wMDBaIiwidXBkYXRlZF9hdCI6IjIwMTktMTItMjBUMTc6NTc6NDUuMDAwWiJ9LCJpYXQiOjE1NzczNzkzMjAsImV4cCI6MTU3NzM5MzcyMH0.rxxH75894759873495873948fjsddfjkhsdkjfhsjk';
    
    // const users = [
    //     {
    //         email : 'user-upload@cadem.cl',
    //         password: 'AaBb1234#',
    //         rol: 'user-upload',
    //         token: TokenUpload,
    //         vista : 'subir',
    //         bucket : 'be-input'
    //     },
    //     {
    //         email : 'user-download@cadem.cl',
    //         password : 'BbAa1234#',
    //         rol : 'user-download',
    //         token : TokenDownload,
    //         vista : 'ver',
    //         bucket : 'be-clean'

    //     }
    // ]

    //let user  = users.find(elemento => elemento.email == username && elemento.password == password);



            

    // axios.post(API_BASE_URL+'/login', {email: username,password: password} )
    axios.post(API_BASE_URL+'/login-fs', {email: username,password: password} )
    .then(user => {        


        // console.log('user.data.ok', user.data.ok);
        // console.log('user.data.token', user.data.token);
        // console.log('user.data.roles', user.data.role);
        // console.log('user.data', user.data);
        // console.log('user.data.usuario.username', user.data.usuario.username);
        // console.log('user.data.usuario.email', user.data.usuario.email);

        let rol = null;
        if(user.data.ok)
        {
            var vista ='';
            rol = user.data.role;
            localStorage.setItem('userToken', user.data.token);
            localStorage.setItem('rol', user.data.role);
            localStorage.setItem('username', user.data.usuario.username);
            localStorage.setItem('useremail', user.data.usuario.email);

            if(rol != null){
                if(user.data.role == 'rol-admin'){                    
                    // vista = 'dashboard';
                    vista = 'downloadfiles';
                }
                if(user.data.role == 'rol-gestion'){                     
                    vista = 'downloadfiles'                    
                }
                if(user.data.role == 'rol-gestion-admin'){              
                    vista = 'downloadfiles'                    
                } 
                if(user.data.role == 'rol-finanzas'){                     
                    vista = 'estudios'                    
                }                 
            }
       
            ////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////
            console.log('vista: ', vista);
            localStorage.removeItem("NoAutorizado");
            window.location.href = '/' + vista;
        }else{
            Swal.fire('Oops...', 'Usuario o password invalido!', 'error');
        }

    })
    .catch(error => {
        console.log('Error', error);
        Swal.fire('Oops...', 'Usuario o password invalido!', 'error');
    })
    .finally(function () {
        // console.log('Respuesta Finally');
    })


  



    
}