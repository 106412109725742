import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { API_BASE_URL } from "../../configuration/index.js";
import { Redirect } from "react-router";

const Lock = ({ history }) => {
  localStorage.clear();
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // captura y validación inicial del token.
  const params = new URLSearchParams(window.location.search);
  const token = params.get('data');
  const userToken = (!token) ? '' : token;
  localStorage.setItem('userToken', userToken);
  if(userToken === '') return <Redirect to="/login" />;

  // evento submit para actualización de contraseña.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const passwordProcess = password.trim();
    if (!passwordProcess)
      return Swal.fire("Error!", "Debe ingresar su nueva contraseña.", "error");
    if (passwordProcess.length > 18 || passwordProcess.length < 4)
      return Swal.fire(
        "Error!",
        `Su contraseña debe tener un máximo de 18 y mínimo de 4 caracteres y usted ha ingresado ${passwordProcess.length}`,
        "error"
      );
    setIsLoading(true);

    // el token se guarda en localStorage donde un 'interceptor' lo inyecta en la petición.
    await axios
      .put(API_BASE_URL + "/change-password", { password })
      .then(({ data: responseData }) => {
        Swal.fire("Exito!", responseData.message, "success");
        localStorage.clear();
        setIsLoading(false);
        history.replace("/");
      })
      .catch(({ response: { data } }) => {
        setIsLoading(false);
        return Swal.fire("Error!", data.message, "error");
      });
  };

  // efecto que verifica que el token este vigente y que sea para una actualización de contraseña.
  useEffect(() => {
    // localStorage.setItem('userToken', userToken);
    async function myTokenIsExpired() {
      await axios.get(API_BASE_URL+'/my-token-is-expired')
      .catch(({response: { data: responseError } }) => {
        if(responseError.reload){
          history.go(0);
        }else{
          Swal.fire('Error!', 'Enlace de recuperacion expirado', 'error') ;
          history.replace('/login');
        }
      })
    }

    // 1. validar que es token este en la url. 
    myTokenIsExpired();
  }, [])

  return (
    <div className="container-full">
      <div className="container container-xs">
        <div className="text-center">
          <img
            className="mv-lg img-fluid thumb128"
            src="img/logo-cadem.svg"
            alt="Logo Cadem"
          />
        </div>
        <form
          className="cardbox b0 form-validate"
          name="formLogin"
          onSubmit={handleSubmit}
        >
          <div className="cardbox-offset pb0">
            <div className="cardbox-offset-item text-right invisible">
              <div className="btn btn-success btn-circle btn-lg">
                <em className="ion-checkmark-round" />
              </div>
            </div>
          </div>
          <div className="cardbox-heading">
            <div className="cardbox-title text-center">
              Crear nueva contraseña.
            </div>
          </div>
          <div className="cardbox-body">
            <div className="mda-form-group float-label mda-input-group">
              <div className="mda-form-control">
                <input
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  value={password}
                  disabled={isLoading}
                  onChange={({ target }) => setPassword(target.value)}
                />
                <div className="mda-form-control-line" />
              </div>
              <span className="mda-input-group-addon">
                <em className="ion-ios-locked-outline icon-lg" />
              </span>
            </div>
          </div>
          <button className="btn btn-purple" type="submit" disabled={isLoading}>
            {!isLoading ? (
              <span>Actualizar</span>
            ) : (
              <Fragment>
                <span
                  className="spinner-grow spinner-grow-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Cargando ...
              </Fragment>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Lock;
